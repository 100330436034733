import React from "react"
import Img from "gatsby-image"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"

export const fluidImageBadge = graphql`
  fragment fluidImageBadge on File {
    childImageSharp {
      fluid(maxWidth: 150) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const Download = ({ title, sub, text, t }) => {
  const { apple, android } = useStaticQuery(graphql`
    query {
      apple: file(relativePath: { eq: "app-store-badge.png" }) {
        ...fluidImageBadge
      },
      android: file(relativePath: { eq: "google-play-badge.png" }) {
        ...fluidImageBadge
      },
    }
  `)

  return (
    <StyledSections id="download" >
        <ul className="list-unstyled">
          <li>
            <a title={t('download.android_link_title')} href="https://play.google.com/store/apps/details?id=com.cineghost">
              <Img alt={t('download.android_img_alt')} fluid={android.childImageSharp.fluid} />
            </a>
          </li>
          <li>
            <a title={t('download.ios_link_title')} href="https://apps.apple.com/us/app/cineghost/id1500930160?ls=1">
              <Img alt={t('ios.ios_img_alt')} fluid={apple.childImageSharp.fluid} />
            </a>
          </li>
        </ul>
    </StyledSections>
  )
}

const StyledSections = styled.section`
  color: white;
  position: relative;
  text-align: center;
  a {
    width: 200px;
    display:inline-block;
    margin-left: 20px;
    margin-right: 20px;
  }

  li {
    display:inline-block;
  }
`

export default Download


